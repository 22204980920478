.Photograph {
}

.Photograph .PhotographImage {
	display: block;
	width: 100%;
}

.Photograph > .Metadata {
	margin-top: 12px;
}

@media (max-width: 700px) {
	.Photograph > .Metadata {
		margin-top: 6px;
	}
}