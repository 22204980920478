.PhotographList.full-width .Photograph {
	width: 100%;
	margin-bottom: 70px;
}

@media (max-width: 700px) {
	.PhotographList.full-width .Photograph {
		margin-bottom: 25px;
	}
}

.PhotographList.grid > div {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-right:-12px;
}

@media (max-width: 700px) {
	.PhotographList.grid > div {
		margin-right:-5px;
	}
}

.PhotographList.grid .Photograph {
	flex: 1;
	min-width: 228px;
  	max-width: 384px;
  	margin-right: 12px;
	margin-bottom: 18px;
}

@media (max-width: 700px) {
	.PhotographList.grid .Photograph {
		min-width: 160px;
		margin-right: 5px;
		margin-bottom: 5px;
	}
}

@media (max-width: 700px) {
	.PhotographList.hideMetadataOnSmallerScreens .Photograph > .Metadata {
		display: none;
	}
}