.Metadata {
  font-size: 11px;
}

.Metadata .Row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 12px;
}

.Metadata .Row > div {
  flex-grow: 1;
  flex-basis: 0;
}

.Metadata .Location {
	text-align: left;
}

.Metadata.minimum .Location {
  flex-grow: 2;
}

.Metadata .People {
  font-style: italic;
  text-align: center;
}

.Metadata .Date {
  text-align: right;
}

.Metadata .Tags {
  text-align: center;
}