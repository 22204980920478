.App {
  font-family: 'Madera';
  -webkit-font-smoothing: antialiased;
  color: #333;
  margin: 16px;
  font-size: 11px;
  overflow: hidden;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

@media (max-width: 700px) {
	.App {
  		margin: 5px;
	}
}

.App > .Container {
  max-width: 1280px;
  margin: auto;
}

.App > .Container > .Tools {
	margin-bottom: 10px;
}

.App a {
	text-decoration: underline;
	color: #333;
}