.PhotographDetail {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
}

.PhotographDetail > .PhotographContainer {
    margin: auto;
}

.PhotographDetail > .PhotographContainer .BackLink {
    text-decoration: none;
    transform: translate3d(0, 15px, 0);
    opacity: 0;
    transition: transform ease-in-out 0.5s, opacity ease-in-out 0.5s;
}

.PhotographDetail > .PhotographContainer.showMetadata .BackLink {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
}

.PhotographDetail > .PhotographContainer > .Row {
    font-size: 11px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 12px;
    align-items: flex-end;
}

.PhotographDetail > .PhotographContainer > .Row .Tags {
    margin-left: 20px;
    transform: translate3d(0, 15px, 0);
    opacity: 0;
    transition: transform ease-in-out 0.5s, opacity ease-in-out 0.5s;
}

.PhotographDetail > .PhotographContainer.showMetadata > .Row .Tags  {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
}

@media (max-width: 700px) {
    .PhotographDetail > .PhotographContainer > .Row {
        margin: 6px;
    }
}

.PhotographDetail > .PhotographContainer > .Row > div {
    flex-grow: 1;
    flex-basis: 0;
}

.PhotographDetail > .PhotographContainer > .Row .Tags {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    align-items: flex-end;
}

.PhotographDetail > .PhotographContainer > .Row .Tags > a {
    flex-grow: 0;
    flex-basis: auto;
    margin: 0 0 0 4px;
}

.PhotographDetail > .PhotographContainer .PhotographImage {
    position: relative;
	display: block;
    width: 100%;
    z-index: 1;
}

.PhotographDetail > .PhotographContainer .Metadata {
    position: relative;
    margin: 12px;
    transform: translate3d(0, -15px, 0);
    opacity: 0;
    transition: transform ease-in-out 0.5s, opacity ease-in-out 0.5s;
    z-index: 0;

}

.PhotographDetail > .PhotographContainer.showMetadata .Metadata {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
}
  
@media (max-width: 700px) {
    .PhotographDetail > .PhotographContainer .Metadata {
        margin: 6px;
    }
}
 