.Logo {
	text-align: center;
	margin: 30px;
	text-decoration: none;
}

@media (max-width: 700px) {
	.Logo {
		margin: 0px;
		transform: scale(0.6);
	}
}

.Logo .Logo-circle {
	display: block;
	margin: auto;
}

@media (max-width: 700px) {
	.Logo {
		margin: 10px;
	}
}

.Logo .Logo-name {
	margin: 14px 0;
	font-size: 11px;
	font-weight: normal;
	font-style: italic;
}